import { getIn, useFormik } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Button, Form, Grid, Message, Segment } from "semantic-ui-react";
import { EligibilityRule, EligibilityRuleSchema, QueryParams } from "../../shared/api-interfaces";
import AppContext from "../../contexts/AppContext";
import { AppDate, SimpleTextBox } from "../base/AppFormInputs";

const getErrorText = (formik: any, name: string): any => {
  return getIn(formik.touched, name) && getIn(formik.errors, name)
    ? getIn(formik.errors, name)
    : null;
};

interface IProps {
  rule: EligibilityRule;
  query: QueryParams;
  onSave: () => void;
  onClose: () => void;
}
export function AddRuleForm(props: IProps) {
  const [error, setError] = useState("");
  const ctx = useContext(AppContext);

  const formik = useFormik({
    initialValues: props.rule,
    validationSchema: EligibilityRuleSchema,
    onSubmit: async (values: EligibilityRule) => {
      console.log(values);
      try {
        setError("");
        await ctx.api.saveRule(values);
        props.onSave();
      } catch (error) {
        setError(error.message);
      }
    },
  });

  const handleSave = ()=>{
    // In case query was updated since form was loaded
    formik.setFieldValue("query", props.query);
    formik.handleSubmit();
  }

  return (<Segment>
    <Form>
      <Grid container>
        <Grid.Column width={4}>
          <SimpleTextBox
            name="name"
            label="Name"
            formik={formik}
            required={true}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <SimpleTextBox
            name="priority"
            label="Priority"
            formik={formik}
            required={true}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <AppDate
            name="startDate"
            label="Start Date"
            value={formik.values.startDate}
            minDate={new Date()}
            onChanged={(value) => formik.setFieldValue("startDate", value)}
            error={getErrorText(formik, "startDate")}
            required={true}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <AppDate
            name="endDate"
            label="End Date"
            value={formik.values.endDate}
            minDate={moment().add(-1, "d").toDate()}
            onChanged={(value) => formik.setFieldValue("endDate", value)}
            error={getErrorText(formik, "endDate")}
            required={true}
          />
        </Grid.Column>
        <Grid.Column width={16} >
          <Message>
            <ul>
              <li>The current query will be stored as an eligibility rule</li>
              <li>New sign-ups will be notified immediately if they meet the rule criteria</li>
              <li>Creating a new rule with the same name as an existing rule, will overwrite the existing rule</li>
              <li>CAUTION: Existing database records will be exported if they meet the rule criteria. If the query count exceeds vaccine availability, consider exporting with an export limit instead.</li>
            </ul>
          </Message>
        </Grid.Column>
        <Grid.Column width={16} textAlign={"right"} >
          <Button secondary onClick={() => props.onClose()}>Cancel</Button>
          <Button primary onClick={() => handleSave()}>Save</Button>
        </Grid.Column>
      </Grid>
    </Form>
    {error && <Message error>
      <p>{error}</p>
    </Message>}
  </Segment>)
}