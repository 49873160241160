export interface IMultiLangLabel { en: string, es: string }
interface IMultiLangOption extends IMultiLangLabel {
    value: string;
}
interface IQuestionText {
    label: string;
    subLabel?: string;
    subLabelLink?: string;
}

interface IQuestionDefinition {
    en: IQuestionText;
    es: IQuestionText;
    options: IMultiLangOption[]
}

export type LanguageId = "en" | "es";

export type QuestionId = "jurisdiction" | "age" | "livingSituation"
    | "rural" | "healthcare" | "frontlineWorker" | "essentialWorker" | "conditions"
    | "isAuthRep" | "preferredContactMethod";

interface IQuestions {
    jurisdiction: IQuestionDefinition;
    age: IQuestionDefinition;
    livingSituation: IQuestionDefinition;
    rural: IQuestionDefinition;
    healthcare: IQuestionDefinition;
    frontlineWorker: IQuestionDefinition;
    essentialWorker: IQuestionDefinition;
    conditions: IQuestionDefinition;
    isAuthRep: IQuestionDefinition;
    preferredContactMethod: IQuestionDefinition;
}

export const Questions: IQuestions = {
    jurisdiction: {
        en: {
            label: "Jurisdiction",
        },
        es: {
            label: "Jurisdicción",
        },
        options: [
            { value: "CT", en: "Connecticut", es: "Connecticut", },
            { value: "HI", en: "Hawaii", es: "Hawaii", },
            { value: "NH", en: "New Hampshire", es: "New Hampshire", },
            { value: "SC", en: "South Carolina", es: "South Carolina", },
            { value: "VI", en: "US Virgin Islands", es: "US Virgin Islands", },
            { value: "BR", en: "Virginia - Blue Ridge", es: "Virginia - Blue Ridge", },
            { value: "FX", en: "Virginia - Fairfax", es: "Virginia - Fairfax", },
            { value: "LD", en: "Virginia - Loudon", es: "Virginia - Loudon", },
            { value: "RH", en: "Virginia - Richmond", es: "Virginia - Richmond", },
            { value: "VB", en: "Virginia - VA Beach", es: "Virginia - VA Beach", },
            { value: "WV", en: "West Virginia", es: "West Virginia", },
            { value: "ET", en: "California - EL TORO", es: "California - EL TORO", },
        ]
    },
    age: {
        en: {
            label: "Please select your age group",
        },
        es: {
            label: "Seleccione su grupo de edad",
        },
        options: [
            { value: "85+", en: "85 years of age and older", es: "85 años y más" },
            { value: "75-84", en: "75 - 84 years of age", es: "75 – 84 años" },
            { value: "65-74", en: "65 - 74 years of age", es: "65 - 74 años" },
            { value: "60-64", en: "60 - 64 years of age", es: "60 - 64 años" },
            { value: "55-59", en: "55 - 59 years of age", es: "55 - 59 años" },
            { value: "50-54", en: "50 - 54 years of age", es: "50 - 54 años" },
            { value: "45-49", en: "45 - 49 years of age", es: "45 - 49 años" },
            { value: "40-44", en: "40 - 44 years of age", es: "40 - 44 años" },
            { value: "35-39", en: "35 - 39 years of age", es: "35 - 39 años" },
            { value: "30-34", en: "30 - 34 years of age", es: "30 - 34 años" },
            { value: "25-29", en: "25 - 29 years of age", es: "25 - 29 años" },
            { value: "18-24", en: "18 - 24 years of age", es: "18 - 24 años" },
            { value: "16-17", en: "16 - 17 years of age", es: "16 - 17 años" },
        ]
    },
    livingSituation: {
        en: {
            label: "Do any of these categories describe your living situation? Please select one if it applies to you.",
        },
        es: {
            label: "¿Alguna de estas categorías describe su situación de vivienda? Seleccione una opción si se aplica a usted.",
        },
        options: [
            { value: "nursing+long-term", en: "Nursing home or longer-term care facility", es: "Hogar de ancianos o establecimiento de cuidados a largo plazo" },
            { value: "group-home", en: "Group home for people with disabilities", es: "Hogar grupal para personas con discapacidades" },
            { value: "homeless", en: "Homeless", es: "Sin hogar" },
        ]
    },
    rural: {
        en: {
            label: "Do you reside in a rural community?",
            subLabel: "Definition of rural community",
            subLabelLink: "https://www.census.gov/programs-surveys/geography/guidance/geo-areas/urban-rural.html"
        },
        es: {
            label: "¿Reside en una comunidad rural?",
            subLabel: "Definición de comunidad rural",
            subLabelLink: "https://www.census.gov/programs-surveys/geography/guidance/geo-areas/urban-rural.html"
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ]
    },
    healthcare: {
        en: {
            label: "Are you a healthcare worker? Please select one if it applies to you.",
        },
        es: {
            label: "¿Es usted un trabajador de atención médica? Seleccione una opción si se aplica a usted.",
        },
        options: [
            {
                value: "practitioners-technical",
                en: "Healthcare practitioners & technical workers (e.g., physicians, dentists, nurses, pharmacists, physical therapists, respiratory therapists)",
                es: "Profesionales y técnicos de atención médica (p. ej., médicos, dentistas, personal de enfermería, farmacéuticos, fisioterapeutas, terapeutas respiratorios)"
            },
            {
                value: "support",
                en: "Healthcare support (e.g., home health aides, nursing assistants, massage therapists, dental assistants, medical assistants)",
                es: "Apoyo de atención médica (p. ej., auxiliares de salud a domicilio, auxiliares de enfermería, masoterapeutas, asistentes dentales, asistentes médicos)"
            },
        ]
    },
    frontlineWorker: {
        en: {
            label: "Are you a frontline essential worker in one of the sectors or worker groups below? Please select one if it applies to you.",
            subLabel: "Click here for descriptions of sector or worker groups",
            subLabelLink: "https://www.cdc.gov/vaccines/covid-19/categories-essential-workers.html"
        },
        es: {
            label: "¿Es usted un trabajador esencial de primera línea en uno de los sectores o grupos de trabajadores que aparecen abajo? Seleccione una opción si se aplica a usted.",
            subLabel: "Haga clic aquí para ver las descripciones de los sectores o grupos de trabajadores",
            subLabelLink: "https://www.cdc.gov/vaccines/covid-19/categories-essential-workers.html"
        },
        options: [
            { value: "first-responder", en: "First responders (e.g., police officers, firefighters)", es: "Personal de respuesta a emergencias (p. ej., oficiales de policía, bomberos)", },
            { value: "correctional", en: "Correctional officers ", es: "Trabajadores de centros correccionales", },
            { value: "postal", en: "Postal service workers", es: "Trabajadores del servicio postal", },
            { value: "food", en: "Food manufacturing and agriculture (including veterinary services)", es: "Fabricación de alimentos y agricultura (incluidos los servicios veterinarios)", },
            { value: "grocery", en: "Grocery store workers", es: "Trabajadores en tiendas de alimentos", },
            { value: "education", en: "Education workers ", es: "Trabajadores del área de educación", },
            { value: "transit", en: "Public transit workers", es: "Trabajadores de transporte público", },
            { value: "manufacturing", en: "Manufacturing", es: "Fabricación", },
        ]
    },
    essentialWorker: {
        en: {
            label: "Are you an essential worker in one of the sectors or worker groups below? Please select one if it applies to you.",
            subLabel: "Click here for descriptions of sector or worker groups",
            subLabelLink: "https://www.cdc.gov/vaccines/covid-19/categories-essential-workers.html"
        },
        es: {
            label: "¿Es usted un trabajador esencial en uno de los sectores o grupos de trabajadores que aparecen abajo? Seleccione una opción si se aplica a usted.",
            subLabel: "Haga clic aquí para ver las descripciones de los sectores o grupos de trabajadores",
            subLabelLink: "https://www.cdc.gov/vaccines/covid-19/categories-essential-workers.html"
        },
        options: [
            { value: "transportation", en: "Transportation and logistics", es: "Transporte y logística" },
            { value: "food", en: "Food service", es: "Servicios alimentarios" },
            { value: "energy", en: "Energy", es: "Energía" },
            { value: "water", en: "Water and wastewater", es: "Agua y aguas residuales" },
            { value: "housing", en: "Shelter and housing", es: "Refugio y vivienda" },
            { value: "communications", en: "Information technology and communications", es: "Tecnología de la información y comunicaciones" },
            { value: "news", en: "News media", es: "Medios de comunicación" },
            { value: "public-safety", en: "Public safety", es: "Seguridad pública" },
            { value: "public-health", en: "Public health workers", es: "Trabajadores de salud pública" },
            { value: "finance", en: "Finance", es: "Finanzas" },
            { value: "legal", en: "Legal", es: "Legal" },
            { value: "religious", en: "Religious services", es: "Servicios religiosos" },
            { value: "non-grocery-store", en: "Non-grocery store workers", es: "Trabajadores de tiendas que no son de alimentos" },
            { value: "parks", en: "Parks and zoos", es: "Parques y zoológicos" },
            { value: "pet-care", en: "Pet care", es: "Cuidado de mascotas" },
            { value: "community+government", en: "Community and government-based operations", es: "Operaciones comunitarias y gubernamentales" },
        ]
    },
    conditions: {
        en: {
            label: "Do you have any of the conditions below? Please check all that apply to you.",
        },
        es: {
            label: "¿Tiene alguna de las afecciones que se mencionan abajo? Marque todo lo que corresponda.",
        },
        options: [
            { value: "cancer", en: "Cancer", es: "Cáncer" },
            { value: "chronic-kidney", en: "Chronic kidney disease", es: "Enfermedad renal crónica" },
            { value: "copd", en: "Chronic lung diseases, including COPD (chronic obstructive pulmonary disease), asthma (moderate-to-severe), interstitial lung disease, cystic fibrosis, and pulmonary hypertension", es: "Enfermedades pulmonares crónicas, como epoc (enfermedad pulmonar obstructiva crónica), asma (de moderada a grave), enfermedad pulmonar intersticial, fibrosis quística e hipertensión pulmonar" },
            { value: "dementia", en: "Dementia or other neurological conditions", es: "Demencia u otras afecciones neurológicas" },
            { value: "diabetes", en: "Diabetes (type 1 or type 2)", es: "Diabetes (tipo 1 o tipo 2)" },
            { value: "down-syndrome", en: "Down syndrome", es: "Síndrome de Down" },
            { value: "heart-conditions", en: "Heart conditions (such as heart failure, coronary artery disease, cardiomyopathies or hypertension)", es: "Afecciones del corazón (como insuficiencia cardiaca, enfermedad de las arterias coronarias, cardiomiopatía o hipertensión)" },
            { value: "hiv", en: "HIV infection", es: "Infección por el VIH" },
            { value: "immunocompromised", en: "Immunocompromised state (weakened immune system)", es: "Estado inmunodeprimido (sistema inmunitario debilitado)" },
            { value: "liver-disease", en: "Liver disease", es: "Enfermedad hepática" },
            { value: "obesity", en: "Overweight and obesity", es: "Sobrepeso y obesidad" },
            { value: "pregnancy", en: "Pregnancy", es: "Embarazo" },
            { value: "sickle-cell", en: "Sickle cell disease or thalassemia", es: "Enfermedad de células falciformes o talasemia" },
            { value: "smoking", en: "Smoking, current or former", es: "Fumador, actual o exfumador" },
            { value: "transplant", en: "Solid organ or blood stem cell transplant", es: "Trasplante de órgano sólido o de células madre" },
            { value: "stroke", en: "Stroke or cerebrovascular disease, which affects blood flow to the brain", es: "Accidente cerebrovascular o enfermedad cerebrovascular, lo cual afecta el flujo de sangre al cerebro" },
            { value: "substance", en: "Substance use disorders", es: "Trastornos por consumo de sustancias" },
        ]
    },
    isAuthRep: {
        en: {
            label: "I am filling this information out for someone else",
        },
        es: {
            label: "Spanish version",
        },
        options: [
            { value: "true", en: "Yes, I am an authorized representative submitting on behalf of someone else.", es: "Spanish Version" },
            { value: "false", en: "No, I am submitting this for myself.", es: "Spanish Version" },
        ]
    },
    preferredContactMethod: {
        en: {
            label: "Registration/communication preference",
        },
        es: {
            label: "Preferencia para la inscripción/las comunicaciones",
        },
        options: [
            { value: "email", en: "Email", es: "Email" },
            { value: "sms", en: "SMS (cell phone)", es: "SMS (teléfono celular)" },
        ]
    },
};

export interface IOption {
    value: string;
    text: string;
}
export interface IQuestionData { label: string, subLabel: string; subLabelLink: string, options: IOption[] }

export function getQuestionForLanguage(id: QuestionId, lang: LanguageId = "en"): IQuestionData {
    const d = Questions[id];
    const labels = d[lang];
    const options = d.options?.map(o => ({ value: o.value, text: o[lang] }));
    return {
        label: labels.label,
        subLabel: labels.subLabel,
        subLabelLink: labels.subLabelLink,
        options: options,
    };
}

export function getQuestionOptionValues(id: QuestionId, lang: LanguageId = "en"): string[]{
    return getQuestionForLanguage(id, lang).options.map(x=> x.value);
}

/**
 * Defines translation labels
 */
export type LabelId = "dob" | "footerSupportLabel" | "footerInfoLabel" | "dobRequired" | "dobFormat" | "recipientInformation" | "firstName" | "firstNameRequired" | "firstNameFormat"| "lastName" | "lastNameRequired" | "lastNameFormat"
        | "zip" | "zipRequired" | "zipFormat" | "contactInformation" | "contactInformationDescription" | "phone" | "phoneRequired" | "phoneFormat" | "phoneExists" | "otpVerification" | "otpDescription" | "otpIncorrect" |"email" 
        | "confirmEmail" | "emailMismatch" | "emailRequired" | "emailExists" | "authRepFirstName" | "authRepLastName" | "resendCode" | "print" | "updateRecord" 
        | "submit" | "reset" | "requiredValidation" | "requiredLegend" | "success" | "eligible" | "exported" | "error" | "existingRecordFound";
interface ILabels {
    dob: IMultiLangLabel;
    footerSupportLabel: IMultiLangLabel;
    footerInfoLabel: IMultiLangLabel;
    dobRequired: IMultiLangLabel;
    dobFormat: IMultiLangLabel;
    recipientInformation: IMultiLangLabel;
    firstName: IMultiLangLabel;
    firstNameRequired: IMultiLangLabel;
    firstNameFormat: IMultiLangLabel;
    lastName: IMultiLangLabel;
    lastNameRequired: IMultiLangLabel;
    lastNameFormat: IMultiLangLabel;
    zip: IMultiLangLabel;
    zipRequired: IMultiLangLabel;
    zipFormat: IMultiLangLabel;
    contactInformation: IMultiLangLabel;
    contactInformationDescription: IMultiLangLabel;
    phone: IMultiLangLabel;
    phoneRequired: IMultiLangLabel;
    phoneFormat: IMultiLangLabel;
    phoneExists: IMultiLangLabel;
    otpVerification: IMultiLangLabel;
    otpDescription: IMultiLangLabel;
    otpIncorrect: IMultiLangLabel;
    email: IMultiLangLabel;
    confirmEmail: IMultiLangLabel;
    emailMismatch: IMultiLangLabel;
    emailRequired: IMultiLangLabel;
    emailExists: IMultiLangLabel;
    authRepFirstName: IMultiLangLabel;
    authRepLastName: IMultiLangLabel;
    resendCode: IMultiLangLabel;
    print: IMultiLangLabel;
    updateRecord: IMultiLangLabel;
    submit: IMultiLangLabel;
    reset: IMultiLangLabel;
    requiredValidation: IMultiLangLabel;
    requiredLegend: IMultiLangLabel;
    success: IMultiLangLabel;
    eligible: IMultiLangLabel;
    exported: IMultiLangLabel;
    error: IMultiLangLabel;
    existingRecordFound: IMultiLangLabel;
}

const Labels: ILabels = {
    dob: {
        en: "What is your Date of Birth?",
        es: "¿Cuál es su fecha de nacimiento?"
    },
    footerSupportLabel: {
        en: "Have Questions?",
        es: "¿TIENE PREGUNTAS?",
    },
    footerInfoLabel: {
        en: "CDC INFORMATION",
        es: "INFORMACIÓN DE LOS CDC",
    },
    dobRequired: {
        en: "Date of birth is required",
        es: "Fecha de nacimiento es requerido"
    },
    dobFormat: {
        en: "Format: m/d/yyyy",
        es: "Formato: m/d/aaaa (mes, día, año)"
    },
    recipientInformation: {
        en: "Recipient Information",
        es: "Información de la persona que recibe la vacuna"
    },
    firstName: {
        en: "First Name",
        es: "Primer nombre"
    },
    firstNameRequired: {
        en: "Your first name is required",
        es: "Debe ingresar su primer nombre"
    },
    firstNameFormat: {
        en: "Contains invalid character(s)",
        es: "Contiene caracteres inválidos"
    },
    lastName: {
        en: "Last Name",
        es: "Apellido"
    },
    lastNameRequired: {
        en: "Your last name is required",
        es: "Debe ingresar su apellido"
    },
    lastNameFormat: {
        en: "Contains invalid character(s)",
        es: "Contiene caracteres inválidos"
    },
    contactInformation: {
        en: "Contact Information",
        es: "Información de contacto"
    },
    contactInformationDescription: {
        en: "You can register for a vaccine using either an email or a cell phone number. You will receive information about your vaccine registration based on the method you select.",
        es: "Puede inscribirse para vacunarse usando una dirección de email o un número de teléfono celular. Usted recibirá la información sobre su inscripción para vacunarse según el método que seleccione."
    },
    zip: {
        en: "Zip Code",
        es: "Código postal"
    },
    zipRequired: {
        en: "Your zip code is required",
        es: "Debe ingresar su código postal"
    },
    zipFormat: {
        en: "Must be a US zip code",
        es: "Debe ser un código postal de los EE. UU."
    },
    phone: {
        en: "Cell Phone",
        es: "Teléfono celular"
    },
    phoneRequired: {
        en: "Your phone number is required",
        es: "Debe ingresar su Teléfono celular"
    },
    phoneFormat: {
        en: "Phone number must be 10 digits",
        es: "El número de teléfono debe tener 10 dígitos"
    },
    phoneExists: {
        en: "The cell phone number has already been used to submit for vaccine eligibility. Please provide a unique cell phone number to proceed.",
        es: "El número de teléfono celular ya ha sido utilizado para enviar el formulario de elegibilidad de la vacuna. Provea un número de teléfono celular diferente para proseguir. "
    },
    otpVerification: {
        en: "SMS Verification Code",
        es: "Código de verificación de SMS"
    },
    otpDescription: {
        en: "A code has been sent to the phone number provided. This code expiries after 15 minutes. Click the button again to resend a verification code. Do not refresh the browser.",
        es: "El código ingresado es incorrecto. Intente de nuevo o solicite un código nuevo"
    },
    otpIncorrect: {
        en: "The code entered is incorrect. Try again or request a new code ",
        es: "El código ingresado es incorrecto. Intente de nuevo o solicite un código nuevo "
    },
    email: {
        en: "Email",
        es: "Dirección de email"
    },
    confirmEmail: {
        en: "Confirm Email",
        es: "Confirme su dirección de email"
    },
    emailMismatch: {
        en: "Email does not match.",
        es: "La dirección de email no coincide"
    },
    emailRequired: {
        en: "Your email address is required",
        es: "Debe ingresar su dirección de email"
    },
    emailExists: {
        en: "The email has already been used to submit for vaccine eligibility. Please provide a unique email address to proceed.",
        es: "La dirección de email ya ha sido usada para enviar el formulario de elegibilidad de la vacuna. Provea una dirección de email diferente para proseguir."
    },
    authRepFirstName: {
        en: "Contact First Name",
        es: "Spanish version"
    },
    authRepLastName: {
        en: "Contact Last Name",
        es: "Spanish version"
    },
    resendCode: {
        en: "Resend Code",
        es: "Renviar código"
    },
    print: {
        en: "Print",
        es: "impresión"
    },
    updateRecord: {
        en: "Update Record",
        es: "Actualizar registro"
    },
    submit: {
        en: "Submit",
        es: "Enviar"
    },
    reset: {
        en: "Reset",
        es: "Reiniciar"
    },
    requiredValidation: {
        en: "This question is required",
        es: "Debe responder esta pregunta"
    },
    requiredLegend: {
        en: "Indicates that the field is required",
        es: "Indica que el campo es obligatorio"
    },
    success: {
        en: "Success",
        es: "Éxito"
    },
    eligible: {
        en: "Eligible",
        es: "Elegible"
    },
    exported: {
        en: "Exported",
        es: "Exportado"
    },
    error: {
        en: "Error",
        es: "Error"
    },
    existingRecordFound: {
        en: "Existing Record Found",
        es: "Registro existente encontrado"
    },
};

export function GetLabelForLanguage(id: LabelId, lang: LanguageId = "en"): string {
    return Labels[id][lang];
}