import { getIn, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Message, Segment } from "semantic-ui-react";
import { QueryParams, QueryParamsSchema } from "../shared/api-interfaces";
import AppContext from "../contexts/AppContext";
import { useCount } from "../hooks/useCount";
import { useExport } from "../hooks/useExport";
import { usePreview } from "../hooks/usePreview";
import { defaultQueryParams } from "../model/defaults";
import {
  AgeQuestion,
  HealthcareQuestion,
  LivingSituationQuestion,
  FrontlineWorkerQuestion,
  EssentialWorkerQuestion,
  ConditionsQuestion,
  JurisdictionQuestion,
  PreviewLimitQuestion,
  ExportLimitQuestion,
  RuralQuestion,
  ZipQuestion,
} from "./base/Questions";
import Rules from "./rules/Rules";

export function QueryForm(){
  const [disabled, setDisabled] = useState(false);

  const ctx = useContext(AppContext);

  const [countQuery, setCountQuery] = useState<QueryParams>(null);
  const countResult = useCount(countQuery);

  const [previewQuery, setPreviewQuery] = useState<QueryParams>(null);
  const previewResult = usePreview(previewQuery);

  const [exportQuery, setExportQuery] = useState<QueryParams>(null);
  const exportResult = useExport(exportQuery);

  useEffect(() => {
    setDisabled(countResult.loading || previewResult.loading || exportResult.loading);
  }, [countResult.loading, previewResult.loading, exportResult.loading]);

  useEffect(() => {
    if (exportResult.result.exportStatus === "Complete") {
      triggerCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportResult.result.exportStatus]);

  const formik = useFormik({
    initialValues: defaultQueryParams(ctx.groups.length === 1 ? ctx.groups[0] : ""),
    validationSchema: QueryParamsSchema,
    onSubmit: (values: QueryParams) => {

      // This forces hook to run even if query didnt change
      const params = Object.assign({}, values);
      // (values as any).ts = (new Date()).getTime();

      switch ((values as any).action) {
        case "count":
          setCountQuery(params);
          break;
        case "preview":
          setPreviewQuery(params);
          break;
        case "export":
          setExportQuery(params);
          break;
      }
    },
  });
  
  const getErrorText = (name: string): string => {
    return getIn(formik.touched, name) && getIn(formik.errors, name)
      ? getIn(formik.errors, name)
      : null;
  };

  if (ctx.groups.length === 0) {
    return <Grid container centered>
      <Message error>
        <Message.Header>Error</Message.Header>
        <Message.Content>You are not assigned to any jurisdictions. Please contact your administrator.</Message.Content>
      </Message>
    </Grid>
  }

  const triggerCount = () => {
    formik.setFieldValue("action", "count");
    formik.handleSubmit();
  }

  return (
    <Grid container centered>
      <Grid.Column width={16}>
        <Form success className="query-form" onSubmit={formik.handleSubmit}>
          <Grid container stackable columns={2}>
            <Grid.Column width={16}>
              <Message info>
                <Message.Header>Instructions</Message.Header>
                <Message.Content>
                  <ul>
                    <li>N/A means this question will not be filtered. All records will be returned including blanks</li>
                    <li>Un-checking everything in a section will return records without responses to that question</li>
                    <li>Checking something other than N/A will return those responses</li>
                  </ul>
                </Message.Content>
              </Message>
            </Grid.Column>
            <Grid.Column>
              {ctx.groups.length > 1 && <JurisdictionQuestion formik={formik} disabled={disabled} getErrorText={getErrorText} />}

              <AgeQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
              <LivingSituationQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
              <RuralQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
              <HealthcareQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
              <FrontlineWorkerQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
            </Grid.Column>
            <Grid.Column>
              <EssentialWorkerQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
              <ConditionsQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
              <ZipQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
            </Grid.Column>
          </Grid>
          <Grid container columns={3}>
            
            <Grid.Column>
                <Segment loading={disabled}>
                <Message info>
                    <p>Query Result Count: {countResult.result !== -1 ? countResult.result.toLocaleString() : "TBD"}</p>
                </Message>

                <Button
                    disabled={countResult.loading}
                    color={"green"}
                    type="button"
                    onClick={() => triggerCount()}
                >Count</Button>
                <Message error visible={countResult.error ? true : false}>
                    <p>{countResult.error}</p>
                </Message>
                </Segment>
            </Grid.Column>
            <Grid.Column>
                <Segment loading={disabled}>
                <PreviewLimitQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
                <Button
                    disabled={disabled}
                    type="button"
                    color={"yellow"}
                    onClick={() => {
                    formik.setFieldValue("action", "preview");
                    formik.handleSubmit();
                    }}
                    content="Preview Results"
                />
                <Message error visible={previewResult.error ? true : false} onDismiss={() => setPreviewQuery(null)}>
                    <p>{previewResult.error}</p>
                </Message>
                </Segment>
            </Grid.Column>
            
            <Grid.Column>
                <Segment loading={disabled}>
                <ExportLimitQuestion formik={formik} disabled={disabled} getErrorText={getErrorText}/>
                <Button
                    color={"red"}
                    disabled={disabled}
                    type="button"
                    onClick={() => {
                    formik.setFieldValue("action", "export");
                    formik.handleSubmit();
                    }}
                    content="Export to VAMS"
                />
                </Segment>
                <Message
                hidden={exportResult.result.exportStatus ? false : true}
                visible={exportResult.result.exportStatus ? true : false}
                error={exportResult.result.exportStatus === "Error"}
                success={exportResult.result.exportStatus === "Complete"}
                warning={["Requesting", "Searching", "Exporting"].includes(exportResult.result.exportStatus)}
                >
                <strong>{exportResult.result.exportStatus}</strong>
                <p>{exportResult.result.statusMessage}</p>
                </Message>
            </Grid.Column>
          </Grid>
        </Form>
      </Grid.Column>
      <Grid.Column width={15}>
        <Rules showCreateButton={formik.isValid} query={formik.values} replaceQuery={query => formik.setValues(query)} />
      </Grid.Column>
    </Grid>
  );
};
