import { QueryParams } from "../shared/api-interfaces";

export function defaultQueryParams(jurisdiction: string): QueryParams {
  return {
    jurisdiction: jurisdiction,
    age: [],
    healthcare: ["*"],
    livingSituation: ["*"],
    rural: ["*"],
    frontlineWorker: ["*"],
    essentialWorker: ["*"],
    conditions: ["*"],
    zip: "",
    previewLimit: 50,
    exportLimit: 50000,
  }
}